import axios from "axios";
import { firestore } from "containers/firebase";
import { COLLECTION } from "shared/strings/firebase";
import { firestoreToArray } from "./helpers";
import batchUtils from "utils/batchUtils";

const { REACT_APP_CLOUD_FUNCTION_API_URL } = process.env;

// common for cities and organisations
export const getCompletelyStats = async ({ ownerType, ownerId, period, periodKey, branchId, withAllUsers, startDate, endDate }) => {
  // console.log(`getCompletelyStats`)
  if (period == null) {
    return;
  }

  // const localKey = `ownerType=${ownerType}&ownerId=${ownerId}`;
  // const localStored = JSON.parse(localStorage.getItem(localKey) ?? '{}');

  // if (moment(localStored.updateTimestamp).isSame(moment(), "date")) {
  //   if (localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`]) {
  //     console.log("return LocalStorage for", `period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`);
  //     return localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`];
  //   }
  // } else {
  //   localStorage.setItem(localKey, JSON.stringify({
  //     ...localStored,
  //     refreshed: false,
  //     data: {}
  //   }));
  // }

  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/v2/get-stats-by?ownerType=${ownerType}&ownerId=${ownerId}&period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error getCompletelyStats : ${err}`) });

  // try {
  //   console.log(`setItem new data with localKey = ${localKey}`)
  //   localStorage.setItem(localKey, JSON.stringify({
  //     ...localStored,
  //     updateTimestamp: Date.now(),
  //     refreshed: localStored.refreshed || false,
  //     data: {
  //       ...(localStored.data || {}),
  //       [`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`]: res,
  //     }
  //   }));
  // } catch (error) {
  //   console.log('Maximum local storage size error in get-stats-by: ' + error.message)
  // }

  return res;
};

export const getUsersActivities = async ({ ownerType, ownerId, period, periodKey, branchId, withAllUsers, startDate, endDate }) => {
  if (period == null) {
    return;
  }
  // console.log(`getUsersActivities`)
  // const localKey = `ownerType=${ownerType}&ownerId=${ownerId}&userActivities=${true}`;
  // const localStored = JSON.parse(localStorage.getItem(localKey) ?? '{}');

  // if (moment(localStored.updateTimestamp).isSame(moment(), "date")) {
  //   if (localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}&userActivities=${true}`]) {
  //     return localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}&userActivities=${true}`];
  //   }
  // } else {
  //   localStorage.setItem(localKey, JSON.stringify({
  //     ...localStored,
  //     refreshed: false,
  //     data: {}
  //   }));
  // }

  console.log(`calling get-useractivities-stats-by?ownerType=${ownerType}`)
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/v2/get-useractivities-stats-by?ownerType=${ownerType}&ownerId=${ownerId}&period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-useractivities-stats-by : ${err}`) });

  // try {
  //   localStorage.setItem(localKey, JSON.stringify({
  //     ...localStored,
  //     updateTimestamp: Date.now(),
  //     refreshed: localStored.refreshed || false,
  //     data: {
  //       ...(localStored.data || {}),
  //       [`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}&userActivities=${true}`]: res,
  //     }
  //   }));
  // } catch (error) {
  //   console.log('Maximum local storage size error in get-useractivities-stats-by: ' + error.message)
  // }

  return res;

};

export const getCityAllOrganisations = (cityId) => {
  return firestore.collection(COLLECTION.Organisations)
    .where('cityId', '==', cityId)
    .get()
    .then(firestoreToArray);
};

export const onceUpdateAllDocumentsInCollection = async (collectionName, data) => {
  if (!collectionName || !data) {
    throw new Error(`Invalid collectionName: ${collectionName} or data: ${data}`);
  }
  await firestore
    .collection(collectionName)
    .get()
    .then(async (snapshot) => {
      const docsWitId = firestoreToArray(snapshot);;
      return batchUtils.batchLimitParallel({
        firestore: firestore,
        items: docsWitId,
        limit: 250,
        onEach: async (doc, batch) => {
          const docRef = firestore.collection(collectionName).doc(doc.id);
          batch.update(docRef, data);
        },
      });
    });
};


export const fetchDashboardSustainableDistance = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-sustainable-distance?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-sustainable-distance : ${err}`) });

  return res;
};


export const fetchDashboardGHG = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-ghg?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-ghg : ${err}`) });

  return res;
};


export const fetchDashboardSustainableSessions = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  console.log('startDate', startDate);
  console.log('endDate', endDate)
  console.log(`${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-sustainable-sessions?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
  )
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-sustainable-sessions?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-sustainable-sessions : ${err}`) });

  return res;
};


export const fetchDashboardTotalGreenpoints = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-greenpoints?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-greenpoints : ${err}`) });

  return res;
};

export const fetchDashboardAllGreenpoints = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-all-greenpoints?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-all-greenpoints : ${err}`) });

  return res;
};

export const fetchDashboardActiveUsersCount = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-active-users-count?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-active-users-count : ${err}`) });

  return res;
};

export const fetchDashboardTotalActivities = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-activities?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-activities : ${err}`) });

  return res;
};

export const fetchDashboardTotalPeriods = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-periods?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-periods : ${err}`) });

  return res;
};

export const fetchDashboardTotalUsers = async ({ ownerType, ownerId, challengeId, startDate, endDate, branchId }) => {
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/get-total-users?ownerType=${ownerType}&ownerId=${ownerId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-total-users : ${err}`) });

  return res;
};

export const fetchCarpoolEvents = ({ role, ownerId }) => {
  return firestore
    .collection(role === 'city' ? COLLECTION.Cities : COLLECTION.Organisations)
    .doc(ownerId)
    .collection(COLLECTION.carpoolEvents)
    .get()
    .then(firestoreToArray);
}

export const fetchCarpoolEventsStream = ({ role, ownerId }) => {
  return firestore
    .collection(role === 'city' ? COLLECTION.Cities : COLLECTION.Organisations)
    .doc(ownerId)
    .collection(COLLECTION.carpoolEvents);
}


export const fetchSingleCarpoolEvent = ({ role, ownerId, eventId }) => {
  return firestore
    .collection(role === 'city' ? COLLECTION.Cities : COLLECTION.Organisations)
    .doc(ownerId)
    .collection(COLLECTION.carpoolEvents)
    .doc(eventId)
    .get()
    .then(doc => ({...doc.data(), id: doc.id}));
}

export const getSingleCarpoolEventRef = ({ role, ownerId, eventId }) => {
  const base = firestore
    .collection(role === 'city' ? COLLECTION.Cities : COLLECTION.Organisations)
    .doc(ownerId)
    .collection(COLLECTION.carpoolEvents);

  return eventId ? base.doc(eventId) : base.doc();
}

export const createCarpoolEventApi = async ({ ownerId, eventId, role }) => {
  const res = await axios
    .post(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/carpoolApiRun/create-carpool-event/${ownerId}/${eventId}/${role}`,
      {}
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error create-carpool-event : ${err}`) });

  return res;
};
