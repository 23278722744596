import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Button, Card } from '@material-ui/core';

import { logout } from "../../../redux/actions/authAction";
import SidebarLink from "./SidebarLink";
import { routes } from "../../App/Router";
import { Firebase } from "../../firebase";

import allons from "../../../assets/images/allons.png";
import CardBox from "atomicComponents/CardBox";
import { CardBody } from "reactstrap";

export default function OrganisationSidebar() {
	const [t] = useTranslation("common");
	const authDispatch = useDispatch();
	const history = useHistory();

	const firebase = React.useMemo(() => new Firebase(), []);
	const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

	const handleLogout = async (evt) => {
		console.log('log out', evt);
		evt?.preventDefault();
		try {
			await firebase._signOut();
			authDispatch(logout());
			history.push(routes.organisation.login);
		} catch (error) {
			toast.error(t('global.something_went_wrong'));
		}
	};
	return (
		<ul className="sidebar__block">
			<SidebarLink
				title={t('dashboard_fitness.page_title')}
				icon="home"
				route={routes.organisation.dashboard}
			/>
			<SidebarLink
				title={t('account.title')}
				icon="user"
				route={routes.organisation.profile}
			/>

			<SidebarLink
				title={t("account.profile.carpool_events")}
				icon="target"
				route={routes.organisation.carpoolEvents}
			/>
			<SidebarLink
				title={t('challenge.page_title')}
				icon="target"
				route={routes.organisation.challengeDashboard}
			/>
			{
				projectId == "greenplaysherbrooke" ?
					<div>
					</div>
					: <div>
						<SidebarLink
							title={t('branch.page_title')}
							icon="file-add"
							route={routes.organisation.branch}
						/>
					</div>
			}

			<SidebarLink
				title={t('FAQ')}
				icon="sidebar__link-icon lnr lnr-bubble"
				route={routes.organisation.FAQ}
			/>
			{/* <SidebarLink
				title={t('account.profile.settings')}
				icon="sidebar__link-icon lnr lnr-bubble"
				route={routes.organisation.settings}
			/> */}
			<SidebarLink
				title={t('log_out')}
				icon="exit"
				onClick={handleLogout}
				route={routes.organisation.login}
			/>
			<br>
			</br>	<br>
			</br>	<br>
			</br>
			<div style={{
				padding: '20px'
			}}><a href="https://greenplay.social/fr/allons/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
					<img style={{ padding: '10px', textAlign: "center" }} src={allons} alt="info" />
					<Card style={{
						padding: '10px',

						boxShadow: '2px 8px 16px 2px rgba(0, 0, 0, 0.2)', // Augmenter la taille et l'opacité de l'ombre
						cursor: 'pointer',
						borderRadius: '8px', // Ajouter des coins arrondis pour un meilleur look
						borderBlockColor: "black",
						background: 'linear-gradient(to bottom,#1a4375, #40e3a6 )' // Fond dégradé
					}}>

						<div style={{ padding: '10px', textAlign: "center" }}>
							<span style={{ color: '#fff' }}>Découvrez notre solution de covoiturage pour entreprises</span> {/* Texte en blanc pour le contraste */}
						</div>
					</Card>
				</a></div>



		</ul>
	);
}
